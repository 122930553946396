import { Component } from '@angular/core';
import { SocketService } from './socket.service';
import OneSignal from 'onesignal-cordova-plugin';
import { Router } from '@angular/router';

import { NavController, MenuController, LoadingController, AlertController, ToastController, Platform, IonSlides } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user: any;
  username: any;
  navigate: any;
  readonly googlePlayLink: string;
  readonly appStoreLink: string;
  menucontent: boolean = false;
  public settings:any;
  constructor(private socket: SocketService, private menu: MenuController, private storage: Storage, public platform: Platform, public toastCtrl: ToastController, private route: Router, public alertController: AlertController,
    private location: Location) {

      platform.ready().then(() => {
        this.OneSignalInit();
      });
    var onevent = this.socket.socket.onevent;
    this.socket.socket.onevent = function (pkt) {

      onevent.call(this, pkt);

    };
    this.socket.socket.on('disconnect', (function (data: any) {
      this.socket.socket.emit('connection');
    }).bind(this));

  }
  OneSignalInit() {
   
    OneSignal.setAppId("9adf2973-6d8a-4a42-9ce0-a3a98d97deaa");
    OneSignal.setNotificationOpenedHandler(function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        // alert(jsonData);
    });
    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
    });

  }
  getlogout() {
    this.socket.socket.on('session-expired', (function (data: any) {

      this.presentToast('top');
      this.storage.remove('akatsuki-partner');
      this.route.navigate(['']);
    }).bind(this));
  }
  async ngOnInit() {
    this.getlogout();
    await this.storage.create();
    this.storage.get('akatsuki-partner').then((val) => {
      this.user = JSON.parse(val);
      if (val) {
        if (this.user) {
          this.username = this.user.details.username;
          this.route.navigate(['/tabs']);
        }
        else{
          this.route.navigate(['']);
          console.log('No local user details found. Going to Welcome page.');
        }
      }
    });
    this.storage.get('akatsuki-partner-settings').then((val)=>{
      this.settings = JSON.parse(val);
    });

    this.socket.socket.on('get-login-status', (function(output:any){
      this.storage.get('akatsuki-partner').then((val) => {
        this.user = JSON.parse(val);
        this.socket.socket.emit('login-status', {user:this.user});
      });
    }).bind(this));

    this.socket.socket.on('get-user-details-success',(function(o:any){
      this.storage.get('akatsuki-partner').then((val) => {
        this.user = JSON.parse(val);
        this.user.details = o.userDetails;
        this.storage.set('akatsuki-partner', JSON.stringify(this.user));
      });
    }).bind(this));
    this.socket.socket.on('get-user-details-error',(async function(o:any){
      let toast =await this.toastCtrl.create({
        message: "Error occured during login. Please check you username and password and login again.",
        duration: 5000,
        position: 'top'
      });
      toast.present();
      this.storage.remove('akatsuki-partner').then(()=>{
        this.route.navigate(['']);
      });
    }).bind(this));

    this.socket.socket.on('multiple-login', (async function(data:any){
      let toast =await this.toastCtrl.create({
        message: "You logged in from a new device. All other devices are logged out from your account.",
        duration: 5000,
        position: 'top'
      });
      toast.present();
    }).bind(this));
    this.socket.socket.on('session-expired', (async function(data:any){
      let toast =await this.toastCtrl.create({
        message: "Your session is expired. Please login again.",
        duration: 3000,
        position: 'top'
      });
      toast.present();
      this.storage.remove('akatsuki-partner').then(()=>{
        this.route.navigate(['']);
      });
    }).bind(this));

    this.socket.socket.on('logout', (async function(data:any){
      let toast = await this.toastCtrl.create({
        message: "Logged out successfully.",
        duration: 2000,
        position: 'top'
      });
      toast.present();
      this.storage.remove('akatsuki-partner').then(()=>{
        this.route.navigate(['']);
      });
    }).bind(this));
  }
  async presentToast(top) {
    const toast = await this.toastCtrl.create({
      message: 'Your session is expired. Please login again',
      duration: 2000,
      position: top
    });
    toast.present();
  }


  logout() {
    this.storage.remove('akatsuki-partner');
    sessionStorage.clear();
    localStorage.clear();
    this.route.navigate(['']);
  }
  home() {
    this.route.navigate(['/tabs']);
  }
  userroute() {
    this.route.navigate(['tabs/users']);
  }
  ledger() {
    this.route.navigate(['/tabs/ledger']);
  }
  setting(){
    this.route.navigate(['/tabs/setting']);
  }
 
  wheelspinner() {
    this.route.navigate(['/wheelspinner']);
  }
  profitloss() {
    this.route.navigate(['/profit-loss']);
  }
  summaryReport() {
    this.route.navigate(['/tabs/summary']);
  }
  openBet() {
    this.route.navigate(['/open-bets']);
  }
  openwalletmanager() {
    this.route.navigate(['/wallet-manage']);
  }
  Setting() {
    this.route.navigate(['/setting']);
  }
  offersdetail(){
    this.route.navigate(['/offers-details']);
  }
  points(){
    this.route.navigate(['/stack-points']);
  }
  filterpoints(){
    this.route.navigate(['/stack-filter']);
  }

}