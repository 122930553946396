import { Injectable } from '@angular/core';
import  io from 'socket.io-client';
@Injectable()
export class SocketService {

  //  live
  private url = '178.62.77.178:8191/';
  
  //test 
 // private url = '142.93.40.145:8191/';
  public socket;
  constructor() {
  // this.socket = io(this.url); 
  this.socket = io(this.url, {transports: ["websocket"], path: '/Dcd7pwimDyfKiPvTadgGH/socket.io'});
  // this.socket = io(this.url, {transports: ["websocket"], path: '/socket.io'});   
  }
  login(formData:any){    
    this.socket.emit('login',{user:formData});
     }
}