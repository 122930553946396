import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'ledger',
    loadChildren: () => import('./ledger/ledger.module').then( m => m.LedgerPageModule)
  },
  {
    path: 'market',
    loadChildren: () => import('./market/market.module').then( m => m.MarketPageModule)
  },
  {
    path: 'create-user',
    loadChildren: () => import('./create-user/create-user.module').then( m => m.CreateUserPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'wheelspinner',
    loadChildren: () => import('./wheelspinner/wheelspinner.module').then( m => m.WheelspinnerPageModule)
  },
  {
    path: 'profit-loss',
    loadChildren: () => import('./profit-loss/profit-loss.module').then( m => m.ProfitLossPageModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then( m => m.SummaryPageModule)
  },
  
  {
    path: 'open-bets',
    loadChildren: () => import('./open-bets/open-bets.module').then( m => m.OpenBetsPageModule)
  },
  {
    path: 'wallet-manage',
    loadChildren: () => import('./wallet-manage/wallet-manage.module').then( m => m.WalletManagePageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'betlistmodal',
    loadChildren: () => import('./betlistmodal/betlistmodal.module').then( m => m.BetlistmodalPageModule)
  },
  {
    path: 'profitlossreport',
    loadChildren: () => import('./profitlossreport/profitlossreport.module').then( m => m.ProfitlossreportPageModule)
  },
  {
    path: 'bet-commision-model',
    loadChildren: () => import('./bet-commision-model/bet-commision-model.module').then( m => m.BetCommisionModelPageModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module').then( m => m.PartnerPageModule)
  },
  {
    path: 'userexposure',
    loadChildren: () => import('./userexposure/userexposure.module').then( m => m.UserexposurePageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'offers-details',
    loadChildren: () => import('./offers-details/offers-details.module').then( m => m.OffersDetailsPageModule)
  },
  {
    path: 'stack-points',
    loadChildren: () => import('./stack-points/stack-points.module').then( m => m.StackPointsPageModule)
  },
  {
    path: 'stack-filter',
    loadChildren: () => import('./stack-filter/stack-filter.module').then( m => m.StackFilterPageModule)
  },
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
